
import Vue from 'vue';
import { getPhoneNum } from '@/utils/common.js';

export default Vue.extend({
  name: 'Step03ManagerForm',
  components: {},
  props: {
    isUsePhone: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    managerObj: {
      type: Object,
      default() {
        return {
          name: '',
          emailId: '',
          domain: '',
          phone: '',
          mobile: '',
          domainSelect: '',
        };
      },
    },
    formChecker: {
      type: Object,
      default() {
        return {
          manager_name: true,
          manager_hp: true,
          manager_email: true,
        };
      },
    },
  },
  data() {
    return {
      mailSelectBoxLabels: [
        { label: '직접입력', value: '' },
        { label: 'naver.com', value: 'naver.com' },
        { label: 'gmail.com', value: 'gmail.com' },
        { label: 'daum.net', value: 'daum.net' },
        { label: 'hanmail.net', value: 'hanmail.net' },
        { label: 'nate.com', value: 'nate.com' },
        { label: 'hotmail.com', value: 'hotmail.com' },
      ],
      mailSelectBox: '',
    };
  },
  computed: {},
  watch: {},
  methods: {
    changeParent(e) {
      const { name, value } = e.target;
      this.$emit('change-parents', { [name]: value });
    },

    changeParentForPhoneNumber(e) {
      const { name, value } = e.target;
      this.$emit('change-parents', { [name]: getPhoneNum(value) });
    },

    changeMailSelect(e) {
      if (e.target.value === '') {
        return;
      }

      this.$emit('change-parents', { domain: e.target.value, domainSelect: e.target.value });
    },
  },
});
