
import Vue from 'vue';
import { AxiosError } from 'axios';
import NimsStep03TechTemplate from '@/templates/nims/NimsStep03TechTemplate.vue';
import { getNimsOrderInfoProxy } from '@/api/gabia-proxy/index';
import { MygabiaManager, settleMentInfo } from '@/api/gabia-proxy/types';
import { NimsGroup, TechHelpRequestForm } from '@/api/infra-renew/types';
import { getGroupInfo, getGroupManagerList, getTechHelpList, putTechHelpInfo } from '@/api/infra-renew/index';
import { moveToFinalPage } from '@/utils';

// https://temp-application.gabia.com:8080/apply-step-03/nims?hst_no=9998651&type=tech

export default Vue.extend({
  name: 'NimsStep03TechBrain',
  components: { NimsStep03TechTemplate },
  props: {},
  data() {
    return {
      serviceType: '',
      hostingSeqNo: '',
      accessToken: '',
      orderNum: '',
      groupSeqNo: 0,
      equipmentSeqNo: 0,
      idcArea: '9' as '1' | '9',
      settingMethod: 'R' as 'R' | 'S',
      codeArr: [] as string[],
      codeArrDepth2: [] as string[],
      seqNoArr: [] as number[],
      goodIdNoArr: [] as number[],
      isOneMinuteSever: false,
      groupInfo: {} as NimsGroup,
      isRender: false,
      formState: {
        equipmentGoodsList: [] as TechHelpRequestForm[],
        serviceManager: {
          name: '',
          emailId: '',
          domain: '',
          domainSelect: '',
          phone: '',
          mobile: '',
        },
      },
      serviceManangerNo: 0,
      billManangerNo: 0,
      mygabiaManagers: [] as MygabiaManager[],
      settlementList: [] as settleMentInfo[],
    };
  },
  computed: {
    userId(): string {
      return this.$store.state.userInfo?.user_id ?? '';
    },
  },
  watch: {},
  async mounted() {
    this.hostingSeqNo = this.$route.query.hst_no as string;
    await this.getNimsOrderInfo();
    await this.getGroupInfoFnc();
    await this.getEquipmentListFnc();
    await this.getGroupManagers();

    this.settingUiChecker();
    this.$GlobalLoading.hide();
    this.isRender = true;
  },
  methods: {
    async getGroupInfoFnc() {
      try {
        const { data } = await getGroupInfo(this.groupSeqNo);
        this.groupInfo = data.data;
      } catch (error) {
        console.warn(error);
      }
    },
    async goNextStep() {
      try {
        const processItem = async (item) => {
          const params = {
            request_description: item.request_text,
            register_name: this.formState.serviceManager.name,
            register_phone: this.formState.serviceManager.mobile,
            register_email: `${this.formState.serviceManager.emailId}@${this.formState.serviceManager.domain}`,
          };

          const { data } = await putTechHelpInfo(item.infra_seqno, params);

          console.log(data);
        };

        // for...of를 사용하여 비동기 코드를 순차적으로 실행
        for (const item of this.formState.equipmentGoodsList) {
          await processItem(item);
        }

        alert('신청 완료하였습니다. 정보확인 페이지로 이동합니다.');
        this.gotoFinalPage();
      } catch (error) {
        console.log(error);
      }
    },
    async getEquipmentListFnc() {
      try {
        const { data } = await getTechHelpList(this.groupSeqNo, this.equipmentSeqNo);
        console.log(data);

        const equipmentGoodsList = this.settlementList.map((item) => {
          if (item.settlement_goods_list[0].goods) {
            const { expose_name, goods_type, id } = item.settlement_goods_list[0].goods;

            const infraInfo = data.data.filter((infra) => {
              return infra.goods_id === String(id);
            });

            return { expose_name, goods_type, goods_id: String(id), infra_seqno: infraInfo[0].seqno, request_text: '' };
          } else {
            return {
              expose_name: '',
              goods_type: {
                code: '',
                name: '',
              },
              goods_id: '',
              infra_seqno: 0,
              request_text: '',
            };
          }
        });

        this.formState.equipmentGoodsList = equipmentGoodsList;
      } catch (error) {
        console.warn(error);
      }
    },
    async getNimsOrderInfo() {
      try {
        const params = {
          uri: `/v5/payment/${this.hostingSeqNo}`,
          'data[response_option[with_goods_info]]': 'Y',
        };

        const { data } = await getNimsOrderInfoProxy(params);
        this.codeArr = data.settlement_list.map((item) => {
          return item.settlement_goods_list[0].goods?.classification?.parent_classification?.code || 'ERROR';
        });

        this.codeArrDepth2 = data.settlement_list.map((item) => {
          return item.settlement_goods_list[0].goods?.classification?.code || 'ERROR';
        });

        this.orderNum = data.ordernum;
        this.groupSeqNo = data.settlement_list[0].integrated_service_seqno;
        this.equipmentSeqNo = data.settlement_list[0].service_seqno;
        this.settlementList = data.settlement_list;
      } catch (error) {
        console.log(error);
      }
    },
    async getGroupManagers() {
      if (this.groupInfo.group_expire_date) {
        return;
      }

      try {
        const { data } = await getGroupManagerList(this.groupSeqNo);
        const serviceManager = data.data.filter(
          (item) => item.represent_flag === 'Y' && item.manager_kind.code === 'S',
        );

        if (serviceManager.length) {
          this.formState.serviceManager.name = serviceManager[0].manager_name || '';
          this.formState.serviceManager.emailId = serviceManager[0].manager_email.split('@')[0] || '';
          this.formState.serviceManager.domain = serviceManager[0].manager_email.split('@')[1] || '';
          this.formState.serviceManager.mobile = serviceManager[0].manager_mobile || '';
          this.formState.serviceManager.phone = serviceManager[0].manager_phone || '';
          this.serviceManangerNo = serviceManager[0].seqno;
        }
      } catch (error) {
        const errorResponse = (error as AxiosError)?.response?.data || {
          message: '불특정 에러가 발생했습니다.',
        };
        alert(`getGroupBillManagerInfo: ${errorResponse.message}`);
      }
    },
    settingUiChecker() {
      if (this.codeArr.includes('TECHSUPPORT')) {
        this.serviceType = 'tech-support';
      } else {
        alert('신청정보 확인완료하였습니다. 정보확인 페이지로 이동합니다.');
        this.gotoFinalPage();
      }
    },
    changeManagerFromTemplate(params) {
      console.log(params);
      this.formState[params.flag] = params.payload;
    },
    myGabiaManagerInfoSetting(payload) {
      console.log(payload);
      const manager = this.mygabiaManagers.filter((item) => {
        return item.seqno === payload.seqNo;
      });

      this.formState[payload.type] = {
        name: manager[0].hanname,
        emailId: manager[0].email.split('@')[0] || '',
        domain: manager[0].email.split('@')[1] || '',
        phone: manager[0].phone,
        mobile: manager[0].hp_no,
        domainSelect: '',
      };
    },
    gotoFinalPage() {
      moveToFinalPage({
        ordernum: this.orderNum,
      });
    },
    changeFromStateFromChild(payload) {
      this.formState = payload;
    },
  },
});
