
import Vue, { PropType } from 'vue';
import BillLayout from '@/layouts/BillLayout.vue';
import formTitle from '@/assets/data/formTitle/index.json';
import FormLayout from '@/layouts/FormLayout.vue';
import TableLayout from '@/layouts/TableLayout.vue';
import ColumnLayout from '@/layouts/ColumnLayout.vue';
import SkeletonGradient from '@/components/loading/SkeletonGradient.vue';
import Step03ManagerForm from '@/templates/nims/fragments/Step03ManagerForm.vue';
import { NimsGroup, TechHelpRequestForm } from '@/api/infra-renew/types';
import { MygabiaManager } from '@/api/gabia-proxy/types';
import { isEmail, isPhoneNum } from '@/utils/common.js';
import { isEmptyObject, deepCopyObject } from '@/utils/common';
export default Vue.extend({
  name: 'NimsStep03TechTemplate',
  components: { BillLayout, FormLayout, ColumnLayout, TableLayout, SkeletonGradient, Step03ManagerForm },
  props: {
    serviceType: {
      type: String,
      default: '',
    },
    isOneMinuteServer: {
      type: Boolean,
      default: false,
    },
    groupInfo: {
      type: Object as PropType<NimsGroup>,
      default: () => {
        return {
          charge_method: { code: '', label: '' },
          pay_method: { code: '', label: '' },
          extend_day: { code: '', label: '' },
          group_expire_date: '',
          group_name: '',
          service: {
            pay_type: {
              code: '',
              label: '',
            },
            pre_expire_date: '',
          },
        };
      },
    },
    formState: {
      type: Object as PropType<any>,
      default: () => {
        return {
          equipmentGoodsList: [] as TechHelpRequestForm[],
          serviceManager: {
            name: '',
            emailId: '',
            domain: '',
            phone: '',
            domainSelect: '',
            mobile: '',
          },
        };
      },
    },
  },
  data() {
    return {
      formTitle,
      infoText: '140px',
      termText: '340px',
      idcArea: '9' as '1' | '9',
      settingMethod: 'R' as 'R' | 'S',
      groupName: '',
      tmpManagerNo: '',
      myGabiaManagerType: '',
      localFormState: {
        equipmentGoodsList: [] as TechHelpRequestForm[],
        serviceManager: {
          name: '',
          emailId: '',
          domain: '',
          phone: '',
          domainSelect: '',
          mobile: '',
        },
      },
      myGabiaUrl: `${process.env.VUE_APP_MY_GABIA_URL}/myinfo#/manager`,
      isCopyBillManagerVaue: false,
      isGroupNameCurrect: true,
      serviceManagerFormChecker: {
        manager_name: true,
        manager_email: true,
        manager_hp: true,
      },
    };
  },
  computed: {
    mainGroupChargeType(): string {
      return this.groupInfo.service.pay_type.code === 'PA' ? '정기결제' : '일반결제';
    },
    isServerHosting(): boolean {
      return this.serviceType === 'server-hosting';
    },
    serviceTitle(): string {
      return this.isServerHosting
        ? this.formTitle.NimsStep03ServerHosting.text
        : this.formTitle.NimsStep03Colocation.text;
    },
    serviceIconClass(): string {
      return this.isServerHosting
        ? this.formTitle.NimsStep03ServerHosting.iconClass
        : this.formTitle.NimsStep03Colocation.iconClass;
    },
  },
  mounted() {
    this.localFormState = deepCopyObject(this.formState);
    this.groupName = this.groupInfo.group_name;
  },
  methods: {
    getDate(date: string) {
      return date ? date.substring(0, 10) : '-';
    },
    openMyGabiaManager(type: string) {
      this.myGabiaManagerType = type;
      this.$GabiaModal.show('mygabia-manager-select-modal');
    },
    copyBillManagerValue(isCopyVaue: boolean) {
      if (isCopyVaue) {
        this.$emit('copy-bill-manager');
      }
    },
    clodMyGabiaManager() {
      this.$GabiaModal.hide('mygabia-manager-select-modal');
    },
    isCheckedManager(payload: MygabiaManager) {
      return String(payload.seqno) === this.tmpManagerNo;
    },
    changeParents() {
      this.$emit('change-formstate', deepCopyObject(this.localFormState));
    },
    tmpChangeManager(payload) {
      const { detail } = payload;
      this.tmpManagerNo = detail;
    },
    onChanegeIdcArea(payload: { name: string; value: boolean; detail: '1' | '9' }) {
      this.idcArea = payload.detail;
      this.$emit('setting-idc-area', this.idcArea);
    },
    onChanegeSettingMethod(payload: { name: string; value: boolean; detail: 'R' | 'S' }) {
      this.settingMethod = payload.detail;
      this.$emit('setting-method', this.settingMethod);
    },
    setMyManager() {
      this.$emit('set-manager-info', { type: this.myGabiaManagerType, seqNo: this.tmpManagerNo });
      this.$GabiaModal.hide('mygabia-manager-select-modal');
    },
    goNexStep() {
      if (!this.preGroupInfoChecker()) {
        alert('신청자 정보를 확인해 주세요');
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
        return;
      }
      this.$emit('go-final-step');
    },
    preGroupInfoChecker() {
      let checker = true;

      const regexManagerName = /^[a-zA-Zㄱ-힣]{1,15}$/;
      this.isGroupNameCurrect = true;

      this.serviceManagerFormChecker = {
        manager_name: true,
        manager_email: true,
        manager_hp: true,
      };

      this.serviceManagerFormChecker.manager_name = regexManagerName.test(this.formState.serviceManager.name.trim());
      this.serviceManagerFormChecker.manager_email = isEmail(
        `${this.formState.serviceManager.emailId}@${this.formState.serviceManager.domain}`,
      );

      this.serviceManagerFormChecker.manager_hp = isPhoneNum(this.formState.serviceManager.mobile);

      if (
        !this.serviceManagerFormChecker.manager_name ||
        !this.serviceManagerFormChecker.manager_email ||
        !this.serviceManagerFormChecker.manager_hp
      ) {
        checker = false;
      }

      return checker;
    },
    changeManager(flag, payloadObj) {
      const changeObj = {
        ...this.localFormState[flag],
        ...payloadObj,
      };
      this.localFormState[flag] = changeObj;
      this.changeParents();
    },
  },
});
